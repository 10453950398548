// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-js": () => import("./../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-back-cover-js": () => import("./../src/pages/back-cover.js" /* webpackChunkName: "component---src-pages-back-cover-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bookcode-js": () => import("./../src/pages/bookcode.js" /* webpackChunkName: "component---src-pages-bookcode-js" */),
  "component---src-pages-coming-soon-js": () => import("./../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internationalization-js": () => import("./../src/pages/internationalization.js" /* webpackChunkName: "component---src-pages-internationalization-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-toc-js": () => import("./../src/pages/toc.js" /* webpackChunkName: "component---src-pages-toc-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */)
}

